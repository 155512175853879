import { Typography } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import MeBooksLogo from "../assets/mebooks_icon.png";
import SecurityNavigator from "../navigator/SecurityNavigator";

const { Title } = Typography;

interface Props {
  location: any;
}

export default class MobilePage extends Component<Props> {
  render() {
    return (
      <>
        <SecurityNavigator location={this.props.location} />
        <Container>
          <Logo src={MeBooksLogo} />
          <Title level={4}>Supported In Desktop Only</Title>
        </Container>
      </>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
`;
