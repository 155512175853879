import Analytics from "../../models/Analytics";

interface State {
  analytics: Analytics | null;
}

const initState: State = {
  analytics: null,
};

const dataReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_ANALYTICS":
      return {
        ...state,
        analytics: action.payload.analytics,
      };
    default:
      return state;
  }
};

export default dataReducer;
