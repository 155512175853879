import { Form, Input, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import FooterImage1 from "../assets/footer1.png";
import FooterImage2 from "../assets/footer2.png";
import FooterImage3 from "../assets/footer3.png";
import MeBooksLogo from "../assets/mebooks_icon.png";
import { ContentPage } from "../components/LeftBar";
import Loading from "../components/Loading";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { changePassword } from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { RedButton, VerticalContainer } from "../styles/Layout";

const { Title, Link } = Typography;

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
}

interface State {
  password: string;
  confPassword: string;
  passwordError: string;
  confPasswordError: string;
  isLoading: boolean;
}

class ResetPasswordPage extends Component<Props> {
  state: State = {
    password: "",
    confPassword: "",
    passwordError: "",
    confPasswordError: "",
    isLoading: false,
  };

  handleChange = (event: any) => {
    let eventId = event.target.id;

    this.setState({
      [eventId]: event.target.value,
    });
  };

  handleChangeScreen = (type: ContentPage) => {
    this.props.history.push("/" + type);
  };

  handleSubmit = () => {
    if (this.state.password.replace(/\s/g, "").length < 1) {
      this.setState({
        passwordError: "Please enter your password",
      });
    } else if (this.state.confPassword !== this.state.password) {
      this.setState({
        passwordError: "",
        confPasswordError: "Please enter the same password",
      });
    } else {
      this.setState(
        {
          confPasswordError: "",
          passwordError: "",
          isLoading: true,
        },
        async () => {
          let error = await changePassword(
            this.state.password.replace(/\s/g, "")
          );
          if (error)
            this.setState({
              confPasswordError: error,
              isLoading: false,
            });
          else
            this.setState(
              {
                password: "",
                confPassword: "",
                isLoading: false,
              },
              () => {
                window.alert("Password successfully changed!");
              }
            );
        }
      );
    }
  };

  renderFooterImage = () => {
    let footerImages = [];

    let imagesLength = Math.floor(window.innerWidth / 180);
    for (let i = 1; i < imagesLength + 1; i++) {
      switch (i % 3) {
        case 0:
          footerImages.push(
            <FooterImage
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage3}
            />
          );
          break;
        case 1:
          footerImages.push(
            <FooterImage
              style={i === 1 || i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage1}
            />
          );
          break;
        case 2:
          footerImages.push(
            <FooterImage
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage2}
            />
          );
          break;
        default:
          break;
      }
    }

    return footerImages;
  };

  render() {
    if (this.props.authStore.userLoading) return null;

    return (
      <>
        <SecurityNavigator location={this.props.location} />
        <Container>
          {!this.state.isLoading ? (
            <>
              <StyledForm>
                <Logo src={MeBooksLogo} />
                <Title style={{ textAlign: "center", width: 450 }} level={2}>
                  Me Books x PNM Dashboard
                </Title>
                <StyledForm.Item
                  validateStatus={
                    this.state.passwordError ? "error" : "success"
                  }
                  help={
                    this.state.passwordError ? this.state.passwordError : null
                  }
                >
                  <Input
                    id="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    style={{
                      width: 400,
                      height: 40,
                      marginTop: 10,
                      borderRadius: 10,
                    }}
                    placeholder="Password"
                  />
                </StyledForm.Item>
                <StyledForm.Item
                  validateStatus={
                    this.state.confPasswordError ? "error" : "success"
                  }
                  help={
                    this.state.confPasswordError
                      ? this.state.confPasswordError
                      : null
                  }
                >
                  <Input
                    id="confPassword"
                    type="password"
                    value={this.state.confPassword}
                    onChange={this.handleChange}
                    style={{
                      width: 400,
                      height: 40,
                      marginTop: 10,
                      borderRadius: 10,
                    }}
                    placeholder="Confirm Password"
                  />
                </StyledForm.Item>
                <RedButton
                  style={{ width: 400, marginTop: 20, marginBottom: 5 }}
                  onClick={this.handleSubmit}
                >
                  Reset Password
                </RedButton>
                <Link
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={this.handleChangeScreen.bind(
                    this,
                    ContentPage.Dashboard
                  )}
                >
                  Back
                </Link>
              </StyledForm>
            </>
          ) : (
            <Loading />
          )}
          <Footer>{this.renderFooterImage()}</Footer>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(ResetPasswordPage);

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
`;

const Container = styled(VerticalContainer)`
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  z-index: -5;
  overflow: none;
  align-items: center;
`;

const FooterImage = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: -30px;
`;
