import { Select, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import Analytics from "../../models/Analytics";
import Categories from "../../models/Categories";
import { getData, getDataAttribute } from "../../store/actions/dataActions";
import {
  CardContainer,
  ContentContainer,
  RowContainer,
  SeriesImage,
  VerticalContainer,
} from "../../styles/Layout";
import CustomCard from "../CustomCard";
import Loading from "../Loading";

const { Title, Text } = Typography;
const { Option } = Select;

const cardData = ["Total Books for PNM"];

interface Props {
  analytics: Analytics;
  getData: () => void;
}

interface State {
  topAge: [Categories] | [];
  topCategories: [Categories] | [];
}

class Books extends Component<Props> {
  state: State = {
    topAge: [],
    topCategories: [],
  };

  componentDidMount() {
    this.props.getData();
    if (this.props.analytics) this.handleGetTopData();
  }

  componentDidUpdate(prevProps: any) {
    if (
      JSON.stringify(prevProps.analytics) !==
        JSON.stringify(this.props.analytics) &&
      this.props.analytics
    )
      this.handleGetTopData();
  }

  handleGetTopData = async () => {
    const ageData = await getDataAttribute("categories", [
      this.props.analytics.popularAge,
    ]);
    const categoriesData = await getDataAttribute(
      "categories",
      this.props.analytics.popularCategories
    );

    this.setState({
      topAge: ageData,
      topCategories: categoriesData,
    });
  };

  renderOptions = () => {
    const d = new Date();
    const year = d.getFullYear();
    let mondays = [];
    let options: any = [];

    d.setDate(1);

    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    while (d.getFullYear() === year) {
      var pushDate = new Date(d.getTime());
      mondays.push(
        pushDate.getDate() +
          "-" +
          (pushDate.getMonth() + 1) +
          "-" +
          pushDate.getFullYear()
      );
      d.setDate(d.getDate() + 7);
    }

    mondays.map((eachMonday, index) => {
      options.push(
        <Option key={index + 1} value={eachMonday}>
          {"(Week" + (index + 1) + ") " + eachMonday}
        </Option>
      );
      return null;
    });

    return (
      <>
        <Option key={0} value="All">
          All
        </Option>
        {options}
      </>
    );
  };

  renderCard = (details: { title: string; content: string | number }) => {
    return <CustomCard title={details.title} content={details.content} />;
  };

  renderPublishers = () => {
    let dataView: any = [];
    if (this.props.analytics)
      this.props.analytics.topPublishers.map(
        (eachPublisher: string, index: number) => {
          dataView.push(
            <>
              <Text key={index}>
                {index + 1}. {eachPublisher}
              </Text>
              <br />
            </>
          );
          return null;
        }
      );

    return dataView;
  };

  renderAuthors = () => {
    let dataView: any = [];
    if (this.props.analytics)
      this.props.analytics.topAuthors.map(
        (eachPublisher: string, index: number) => {
          dataView.push(
            <>
              <Text key={index}>
                {index + 1}. {eachPublisher}
              </Text>
              <br />
            </>
          );
          return null;
        }
      );

    return dataView;
  };

  renderData = () => {
    let chartData: any = [];
    const day = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    day.map((eachDay, i) => {
      chartData.push({
        index: i,
        x: eachDay,
        y: Math.floor(Math.random() * 1000) + 200,
      });
      return null;
    });

    return chartData;
  };

  renderTopCategories = () => {
    let dataView: any = [];
    if (this.state.topCategories.length > 0)
      this.state.topCategories.map(
        (eachCategory: Categories, index: number) => {
          let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fselection_icon.png?alt=media&token=${eachCategory.selectionIcon}`;
          dataView.push(
            <VerticalContainer style={{ margin: "0px 5px" }} key={index}>
              <SeriesImage src={url} />
              <Title level={5}>{eachCategory.discoverName}</Title>
            </VerticalContainer>
          );
          return null;
        }
      );

    if (dataView.length === 0) return <Loading style={{ height: 160 }} />;
    else return dataView;
  };

  renderContent = () => {
    return (
      <>
        <CardContainer>
          {this.renderCard({
            title: cardData[0],
            content: this.props.analytics ? this.props.analytics.totalBooks : 0,
          })}
          <CustomCard title="Top Age Group">
            <RowContainer>
              {this.state.topAge.length > 0 ? (
                <>
                  <SeriesImage
                    style={{ height: 100 }}
                    src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${this.state.topAge[0]?.id}%2Fselection_icon.png?alt=media&token=${this.state.topAge[0]?.selectionIcon}`}
                  />
                  <Title level={5}>{this.state.topAge[0]?.discoverName}</Title>
                </>
              ) : (
                <Loading style={{ height: 130 }} />
              )}
            </RowContainer>
          </CustomCard>
        </CardContainer>
        <CardContainer>
          <CustomCard width="80%" height="auto" title="Top 3 Categories">
            <RowContainer>{this.renderTopCategories()}</RowContainer>
          </CustomCard>
        </CardContainer>
        {/* <CardContainer>
          <CustomCard width="98%" height="auto">
            <RowContainer style={{ marginBottom: 20 }}>
              <Meta title="Reading Time" />
              <div style={{ flexGrow: 1 }} />
              <Select
                style={{ width: 200, marginRight: 10 }}
                onChange={this.handleDropdown}
                placeholder="All"
                defaultValue={"All"}
              >
                {this.renderOptions()}
              </Select>
            </RowContainer>
            <Bar height={200} data={this.renderData()} />
          </CustomCard>
        </CardContainer> */}
        <CardContainer>
          <CustomCard width="48%" height="auto" title="Top 5 Publishers">
            {this.renderPublishers()}
          </CustomCard>
          <CustomCard width="48%" height="auto" title="Top 5 Authors">
            {this.renderAuthors()}
          </CustomCard>
        </CardContainer>
      </>
    );
  };

  render() {
    return (
      <>
        {this.props.analytics ? (
          <ContentContainer>{this.renderContent()}</ContentContainer>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    analytics: state.dataStore.analytics,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getData: () => dispatch(getData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Books);
