import { UserOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
//Assets
import MeBooksLogo from "../assets/mebooks_icon.png";
//Styles
import { RowContainer } from "../styles/Layout";
//Components
import AuthModal from "./AuthModal";

const { Title } = Typography;

interface Props {
  history: any;
}

class TopBar extends Component<Props> {
  state = {
    authModal: false,
  };

  handleAuthModal = () => {
    this.setState({
      authModal: !this.state.authModal,
    });
  };

  render() {
    return (
      <>
        {this.state.authModal && (
          <AuthModal
            handleAuthModal={this.handleAuthModal}
            history={this.props.history}
          />
        )}
        <Container>
          <RowContainer>
            <Logo src={MeBooksLogo} />
            <Title level={4}>Me Books &nbsp; x &nbsp; PNM Dashboard</Title>
          </RowContainer>
          <div style={{ flex: 1 }} />
          <RowContainer
            style={{ marginRight: 30, cursor: "pointer" }}
            onClick={this.handleAuthModal}
          >
            <Title level={5}>Account</Title>
            <UserOutlined style={{ marginLeft: 5 }} />
          </RowContainer>
        </Container>
      </>
    );
  }
}

export default TopBar;

const Container = styled.div`
  top: 0;
  position: absolute;
  width: 100%;
  height: 50px;
  background: rgb(242, 242, 247);
  align-items: center;
  display: flex;
`;

const Logo = styled.img`
  width: 34px;
  height: 34px;
  margin-left: 25px;
  margin-right: 10px;
`;
