import { Typography } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
//Assets
import MeBooksLogo from "../assets/mebooks_icon.png";
//Styles
import { RedButton } from "../styles/Layout";

const { Title, Text } = Typography;

interface Props {
  history: any;
}

export default class MobilePage extends Component<Props> {
  handleRedirect = () => {
    this.props.history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    return (
      <Container>
        <Logo src={MeBooksLogo} />
        <Title level={4}>404 - Page Not Found</Title>
        <CustomText>
          We looked everywhere for this page.
          <br />
          Are you sure the website URL is correct?
        </CustomText>
        <RedButton
          style={{ width: 280, marginTop: 20 }}
          onClick={this.handleRedirect}
        >
          Back To Home
        </RedButton>
      </Container>
    );
  }
}

const CustomText = styled(Text)`
  margin-top: 10px !important;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
`;
