export interface authStateInterface {
  userLoading: boolean;
  userInfo: any;
}

const initState: authStateInterface = {
  userLoading: true,
  userInfo: null,
};

const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_LOADING":
      return {
        ...state,
        userLoading: false,
      };
    case "UPDATE_USER_INFO":
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    default:
      return state;
  }
};

export default authReducer;
