import { Card, Typography } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
const { Title } = Typography;

interface Props {
  title?: string;
  width?: string | number;
  height?: string | number;
  content?: string | number | undefined;
}

class CustomCard extends Component<Props> {
  render() {
    return (
      <Card
        style={{
          width: this.props.width ? this.props.width : "29%",
          height: this.props.height ? this.props.height : 200,
          margin: "1% 20px",
          border: "1px solid #cccccc",
          borderRadius: 30,
        }}
      >
        <CustomTitle level={5} style={{ marginBottom: 5 }}>
          {this.props.title}
        </CustomTitle>
        <Title level={2}>{this.props.content}</Title>
        {this.props.children && this.props.children}
      </Card>
    );
  }
}

const CustomTitle = styled(Title)`
  margin-bottom: 5px !important;
`;

export default CustomCard;
