import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";

const antIcon = <LoadingOutlined style={{ fontSize: "40px" }} spin />;
const { Text } = Typography;

interface Props {
  style?: any;
}
class Loading extends Component<Props> {
  render() {
    return (
      <LoadingContainer style={this.props.style}>
        <Spin style={{ color: Colors.Red }} indicator={antIcon} />
        <Text style={{ marginTop: 10 }}>Loading ...</Text>
      </LoadingContainer>
    );
  }
}

export default Loading;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
`;
