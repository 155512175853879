import { Pie } from "@ant-design/charts";
import { Select, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import Analytics from "../../models/Analytics";
import { getData } from "../../store/actions/dataActions";
import {
  CardContainer,
  ContentContainer,
  RowContainer,
} from "../../styles/Layout";
import CustomCard from "../CustomCard";
import Loading from "../Loading";

const { Option } = Select;
const { Title, Text } = Typography;

const selectOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const cardData = ["Total Users", "Total Promo Code Redeemed"];

interface Props {
  analytics: Analytics;
  getData: () => void;
}

class Users extends Component<Props> {
  componentDidMount() {
    this.props.getData();
  }

  renderOptions = () => {
    let options: any = [];
    const currentMonth = new Date().getMonth();
    selectOptions.map((eachMonday, index) => {
      options.push(
        <Option
          key={index + 1}
          value={eachMonday}
          disabled={index <= currentMonth ? false : true}
        >
          {eachMonday}
        </Option>
      );
      return null;
    });

    return (
      <>
        <Option key={0} value="All">
          All
        </Option>
        {options}
      </>
    );
  };

  renderDateOptions = () => {
    const d = new Date();
    const year = d.getFullYear();
    let mondays = [];
    let options: any = [];

    d.setDate(1);

    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
    }

    while (d.getFullYear() === year) {
      var pushDate = new Date(d.getTime());
      mondays.push(
        pushDate.getDate() +
          "-" +
          (pushDate.getMonth() + 1) +
          "-" +
          pushDate.getFullYear()
      );
      d.setDate(d.getDate() + 7);
    }

    mondays.map((eachMonday, index) => {
      options.push(
        <Option key={index + 1} value={eachMonday}>
          {"(Week" + (index + 1) + ") " + eachMonday}
        </Option>
      );
      return null;
    });

    return (
      <>
        <Option key={0} value="All">
          All
        </Option>
        {options}
      </>
    );
  };

  renderCard = (details: { title: string; content: string | number }) => {
    return <CustomCard title={details.title} content={details.content} />;
  };

  renderData = () => {
    let chartData: any = [];
    const day = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    day.map((eachDay, i) => {
      chartData.push({
        index: i,
        x: eachDay,
        y: Math.floor(Math.random() * 1000) + 200,
      });
      return null;
    });

    return chartData;
  };

  renderUserChart = () => {
    if (this.props.analytics) {
      let data: any = [];
      this.props.analytics.userStates.map((eachState: any) => {
        const key = Object.keys(eachState)[0];
        const value = eachState[key];
        data.push({
          type: key,
          value,
        });
        return null;
      });
      return data;
    } else return null;
  };

  renderUsersData = () => {
    return (
      <>
        <CardContainer>
          {this.renderCard({
            title: cardData[0],
            content: this.props.analytics
              ? this.props.analytics.totalGuests +
                this.props.analytics.totalMembers
              : 0,
          })}
          {this.renderCard({
            title: cardData[1],
            content: this.props.analytics
              ? this.props.analytics.codeRedeemed
              : 0,
          })}
          <CustomCard title="Spending Time Per User">
            <Text>{`Average : ${
              this.props.analytics ? this.props.analytics.userStats.average : 0
            }`}</Text>
            <br />
            <Text>{`Highest : ${
              this.props.analytics ? this.props.analytics.userStats.highest : 0
            }`}</Text>
          </CustomCard>
        </CardContainer>
        <CardContainer>
          <CustomCard width="80%" height="auto">
            <RowContainer>
              <Title level={5}>Users By Locations</Title>
              <div style={{ flexGrow: 1 }} />
            </RowContainer>
            <Pie
              angleField="value"
              colorField="type"
              data={this.renderUserChart()}
              height={300}
            />
          </CustomCard>
        </CardContainer>
      </>
    );
  };

  render() {
    return (
      <>
        {this.props.analytics ? (
          <ContentContainer>{this.renderUsersData()}</ContentContainer>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    analytics: state.dataStore.analytics,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getData: () => dispatch(getData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
