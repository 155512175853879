import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashboardPage from "../pages/Dashboard";
//Components
import LoginScreenPage from "../pages/LoginScreen";
import MobilePage from "../pages/Mobile";
import NotFoundPage from "../pages/NotFound";
import ResetPasswordPage from "../pages/ResetPassword";
//Actions
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions";

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

class Navigator extends Component<Props> {
  componentDidMount() {
    this.props.getAuthListener();
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreenPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/users" component={DashboardPage} />
          <Route exact path="/books" component={DashboardPage} />
          <Route exact path="/mobile" component={MobilePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
