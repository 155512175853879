import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Content from "../components/Content";
import LeftBar from "../components/LeftBar";
import TopBar from "../components/TopBar";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
}

class DashboardPage extends Component<Props> {
  render() {
    if (this.props.authStore.userLoading) return null;
    return (
      <>
        <SecurityNavigator location={this.props.location} />
        <Container>
          <TopBar history={this.props.history} />
          <LeftBar
            location={this.props.location}
            history={this.props.history}
          />
          <Content
            location={this.props.location}
            history={this.props.history}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(DashboardPage);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;
