import { Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import Analytics from "../../models/Analytics";
import Books from "../../models/Books";
import Series from "../../models/Series";
import { getData, getDataAttribute } from "../../store/actions/dataActions";
import {
  BookImage,
  CardContainer,
  ContentContainer,
  RowContainer,
  SeriesImage,
  VerticalContainer,
} from "../../styles/Layout";
import CustomCard from "../CustomCard";
import Loading from "../Loading";

const { Text } = Typography;

const cardData = [
  "Total Users",
  "Total Book Read",
  "Total Reading Time",
  "Total Voice-overs Created",
  "Highest Usage",
];

interface Props {
  analytics: Analytics;
  getData: () => void;
}

interface State {
  topSeries: [Series] | [];
  topBooks: [Books] | [];
}
class Overview extends Component<Props> {
  state: State = {
    topSeries: [],
    topBooks: [],
  };

  componentDidMount() {
    this.props.getData();
    if (this.props.analytics) this.handleGetTopData();
  }

  componentDidUpdate(prevProps: any) {
    if (
      JSON.stringify(prevProps.analytics) !==
        JSON.stringify(this.props.analytics) &&
      this.props.analytics
    )
      this.handleGetTopData();
  }

  handleGetTopData = async () => {
    let seriesData = await getDataAttribute(
      "series",
      this.props.analytics.popularSeries
    );
    let booksData = await getDataAttribute(
      "books",
      this.props.analytics.popularTitles
    );

    this.setState({
      topSeries: seriesData,
      topBooks: booksData,
    });
  };

  renderCard = (details: {
    title: string;
    content: string | number | undefined;
  }) => {
    return <CustomCard title={details.title} content={details.content} />;
  };

  renderHighestState = () => {
    if (this.props.analytics && this.props.analytics.userStates.length > 0) {
      let highestState: string = "";
      let highestNumber: number = 0;
      this.props.analytics.userStates.map((eachState: any) => {
        if (!highestState) {
          highestState = Object.keys(eachState)[0];
          highestNumber = eachState[highestState];
        } else {
          if (eachState[Object.keys(eachState)[0]] > highestNumber) {
            highestState = Object.keys(eachState)[0];
            highestNumber = eachState[highestState];
          }
        }
        return null;
      });

      return highestState;
    }
  };

  renderTopSeries = () => {
    let dataView: any = [];
    if (this.state.topSeries.length > 0)
      this.state.topSeries.map((eachSeries: Series, index: number) => {
        const url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${eachSeries.id}%2Fimage.png?alt=media&token=${eachSeries.image}`;
        dataView.push(
          <VerticalContainer style={{ margin: "0px 10px" }} key={index}>
            <SeriesImage src={url} />
            <Text>{eachSeries.name}</Text>
          </VerticalContainer>
        );
        return null;
      });

    if (dataView.length === 0) return <Loading style={{ height: 180 }} />;
    else return dataView;
  };

  renderTopBooks = () => {
    let dataView: any = [];
    if (this.state.topBooks.length > 0)
      this.state.topBooks.map((eachBook: Books, index: number) => {
        const url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachBook.id}%2FpreviewImage.png?alt=media&token=${eachBook.image}`;
        dataView.push(<BookImage key={index} src={url} />);
        return null;
      });

    if (dataView.length === 0) return <Loading style={{ height: 180 }} />;
    else return dataView;
  };

  renderContent = () => {
    return (
      <>
        <CardContainer>
          {this.renderCard({
            title: cardData[0],
            content: this.props.analytics
              ? this.props.analytics.totalGuests +
                this.props.analytics.totalMembers
              : 0,
          })}
          {this.renderCard({
            title: cardData[1],
            content: this.props.analytics
              ? this.props.analytics.totalBooksRead
              : 0,
          })}
          {this.renderCard({
            title: cardData[2],
            content: this.props.analytics
              ? this.props.analytics.totalReadingTime
              : 0,
          })}
        </CardContainer>
        <CardContainer>
          {this.renderCard({
            title: cardData[3],
            content: this.props.analytics
              ? this.props.analytics.totalVoiceOvers
              : 0,
          })}
          {this.renderCard({
            title: cardData[4],
            content: this.renderHighestState(),
          })}
        </CardContainer>
        <CardContainer>
          <CustomCard width="80%" height="auto" title="Top 3 Favourite Series">
            <RowContainer>{this.renderTopSeries()}</RowContainer>
          </CustomCard>
        </CardContainer>
        <CardContainer>
          <CustomCard width="80%" height="auto" title="Top 5 Popular Titles">
            {this.renderTopBooks()}
          </CustomCard>
        </CardContainer>
      </>
    );
  };

  render() {
    return (
      <>
        {this.props.analytics ? (
          <ContentContainer>{this.renderContent()}</ContentContainer>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    analytics: state.dataStore.analytics,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getData: () => dispatch(getData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
