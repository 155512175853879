import React, { Component } from "react";
import styled from "styled-components";
import Books from "./dashboard/Books";
import Overview from "./dashboard/Overview";
import Users from "./dashboard/Users";
import { ContentPage } from "./LeftBar";

interface Props {
  location: any;
  history: any;
}
class Content extends Component<Props> {
  renderContent = () => {
    switch (this.props.location.pathname) {
      case "/" + ContentPage.Dashboard:
        return <Overview />;
      case "/" + ContentPage.Users:
        return <Users />;
      case "/" + ContentPage.Books:
        return <Books />;
      default:
        return null;
    }
  };

  render() {
    return <Container>{this.renderContent()}</Container>;
  }
}

export default Content;

const Container = styled.div`
  position: absolute;
  left: 200px;
  top: 50px;
  width: calc(100vw - 200px);
  height: calc(100% - 50px);
`;
