import { Form, Input, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import packageJson from "../../package.json";
import FooterImage1 from "../assets/footer1.png";
import FooterImage2 from "../assets/footer2.png";
import FooterImage3 from "../assets/footer3.png";
import MeBooksLogo from "../assets/mebooks_icon.png";
import Loading from "../components/Loading";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { resetPassword, signIn } from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { RedButton, VerticalContainer } from "../styles/Layout";

const { Title, Link, Text } = Typography;

enum screenType {
  SIGNIN = "signIn",
  FORGET = "forget",
}

interface Props {
  location: any;
  authStore: authStateInterface;
}

interface State {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  isLoading: boolean;
  screen: screenType;
}

class LoginScreen extends Component<Props> {
  state: State = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    isLoading: false,
    screen: screenType.SIGNIN,
  };

  handleChange = (event: any) => {
    let eventId = event.target.id;

    this.setState({
      [eventId]: event.target.value,
    });
  };

  handleChangeScreen = (type: screenType) => {
    this.setState({
      screen: type,
    });
  };

  handleSubmit = (type: screenType) => {
    this.setState(
      {
        emailError: "",
        passwordError: "",
      },
      () => {
        //eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(this.state.email.replace(/\s/g, ""))) {
          this.setState({
            emailError: "Please enter the correct email format",
          });
        }
        if (
          type === screenType.SIGNIN &&
          this.state.password.replace(/\s/g, "").length < 1
        ) {
          this.setState({
            passwordError: "Please enter your password",
          });
        }

        setTimeout(async () => {
          if (type === screenType.SIGNIN) {
            if (!this.state.emailError && !this.state.passwordError) {
              this.setState({ isLoading: true });
              let result = await signIn({
                email: this.state.email.replace(/\s/g, ""),
                password: this.state.password.replace(/\s/g, ""),
              });
              if (result) {
                this.setState({
                  isLoading: false,
                  passwordError: "Your username or password is incorrect",
                });
              }
            }
          } else {
            if (!this.state.emailError) {
              this.setState({ isLoading: true });
              let result = await resetPassword(
                this.state.email.replace(/\s/g, "")
              );
              if (result) {
                this.setState({
                  isLoading: false,
                  emailError: result,
                });
              } else {
                this.setState({
                  isLoading: false,
                  emailError:
                    "A password reset instruction has been sent to your email",
                });
              }
            }
          }
        }, 150);
      }
    );
  };

  renderFooterImage = () => {
    let footerImages = [];

    let imagesLength = Math.floor(window.innerWidth / 180);
    for (let i = 1; i < imagesLength + 1; i++) {
      switch (i % 3) {
        case 0:
          footerImages.push(
            <FooterImage
              key={i}
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage3}
            />
          );
          break;
        case 1:
          footerImages.push(
            <FooterImage
              key={i}
              style={i === 1 || i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage1}
            />
          );
          break;
        case 2:
          footerImages.push(
            <FooterImage
              key={i}
              style={i === imagesLength ? { marginBottom: 40 } : {}}
              src={FooterImage2}
            />
          );
          break;
        default:
          break;
      }
    }

    return footerImages;
  };

  render() {
    if (this.props.authStore.userLoading) return null;

    return (
      <>
        <SecurityNavigator location={this.props.location} />
        <Container>
          <Text style={{ position: "absolute", top: 10, right: 30 }}>
            Version {packageJson.version}
          </Text>
          {!this.state.isLoading ? (
            <StyledForm>
              <Logo src={MeBooksLogo} />
              <Title style={{ textAlign: "center", width: 450 }} level={2}>
                Me Books x PNM Dashboard
              </Title>
              {this.state.screen === screenType.SIGNIN ? (
                <>
                  <StyledForm.Item
                    validateStatus={this.state.emailError ? "error" : "success"}
                    help={this.state.emailError ? this.state.emailError : null}
                  >
                    <Input
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      style={{
                        width: 400,
                        height: 40,
                        marginTop: 20,
                        borderRadius: 10,
                      }}
                      placeholder="Email"
                    />
                  </StyledForm.Item>
                  <StyledForm.Item
                    validateStatus={
                      this.state.passwordError ? "error" : "success"
                    }
                    help={
                      this.state.passwordError ? this.state.passwordError : null
                    }
                  >
                    <Input
                      id="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      style={{
                        width: 400,
                        height: 40,
                        marginTop: 10,
                        borderRadius: 10,
                      }}
                      placeholder="Password"
                    />
                  </StyledForm.Item>
                  <RedButton
                    style={{ width: 400, marginTop: 20, marginBottom: 5 }}
                    onClick={this.handleSubmit.bind(this, screenType.SIGNIN)}
                  >
                    Sign In
                  </RedButton>
                  <Link
                    style={{
                      textDecoration: "underline",
                    }}
                    onClick={this.handleChangeScreen.bind(
                      this,
                      screenType.FORGET
                    )}
                  >
                    Forgot Password
                  </Link>
                </>
              ) : (
                <>
                  <StyledForm.Item
                    validateStatus={this.state.emailError ? "error" : "success"}
                    help={this.state.emailError ? this.state.emailError : null}
                  >
                    <Input
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      style={{
                        width: 400,
                        height: 40,
                        marginTop: 10,
                        borderRadius: 10,
                      }}
                      placeholder="Email"
                    />
                  </StyledForm.Item>
                  <RedButton
                    style={{ width: 400, marginTop: 20, marginBottom: 5 }}
                    onClick={this.handleSubmit.bind(this, screenType.FORGET)}
                  >
                    Reset Password
                  </RedButton>
                  <Link
                    style={{
                      textDecoration: "underline",
                    }}
                    onClick={this.handleChangeScreen.bind(
                      this,
                      screenType.SIGNIN
                    )}
                  >
                    Back
                  </Link>
                </>
              )}
            </StyledForm>
          ) : (
            <Loading />
          )}
          <Footer>{this.renderFooterImage()}</Footer>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(LoginScreen);

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
`;

const Container = styled(VerticalContainer)`
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 90px;
  height: 90px;
  margin-bottom: 5px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  z-index: -5;
  overflow: none;
  align-items: center;
`;

const FooterImage = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: -30px;
`;
