import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dataReducer from "./dataReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  dataStore: dataReducer,
});

export default rootReducer;
