import { Menu } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";

interface Props {
  location: any;
  history: any;
}

export enum ContentPage {
  Dashboard = "dashboard",
  Users = "users",
  Books = "books",
}

enum ContentTitle {
  Dashboard = "Overview",
  Users = "Users",
  Books = "Books",
}

interface State {
  openKeys: [ContentPage];
}

export default class LeftBar extends Component<Props> {
  state: State = {
    openKeys: [ContentPage.Dashboard],
  };

  handleNavigate = (link: string) => {
    this.props.history.push(link);
  };

  renderNavBarContent = (page: ContentPage, title: ContentTitle) => {
    return (
      <Menu.Item
        key={page}
        onClick={() => this.handleNavigate("/" + page)}
        style={
          this.props.location.pathname === "/" + page
            ? { color: Colors.White, background: Colors.LightBlue }
            : undefined
        }
      >
        {title}
      </Menu.Item>
    );
  };

  renderNavBar = () => {
    return (
      <Menu style={{ width: 200 }} mode="inline" openKeys={this.state.openKeys}>
        {this.renderNavBarContent(
          ContentPage.Dashboard,
          ContentTitle.Dashboard
        )}
        {this.renderNavBarContent(ContentPage.Users, ContentTitle.Users)}
        {this.renderNavBarContent(ContentPage.Books, ContentTitle.Books)}
      </Menu>
    );
  };

  render() {
    return <Container>{this.renderNavBar()}</Container>;
  }
}

const Container = styled.div`
  top: 50px;
  position: absolute;
  width: 200px;
  height: calc(100% - 50px);
  border-right: solid 0.8px rgb(229, 229, 234);
`;
