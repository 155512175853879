import { Button } from "antd";
import styled from "styled-components";
import { Colors } from "./Colors";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RedButton = styled(Button)`
  color: ${Colors.White} !important;
  background: ${Colors.Red} !important;
  border-radius: 10px !important;
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  font-family: Visby-Round;

  &:hover {
    cursor: pointer;
    background: ${Colors.LightRed} !important;
  }
`;

export const NavBar = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: calc(100vw - 200px);
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const BookImage = styled.img`
  height: 180px;
  margin: 5px;
  margin-top: 10px;
`;

export const SeriesImage = styled.img`
  height: 150px;
  width: auto;
  margin: 10px;
`;

export const CardContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
`;
