import axios from "axios";

export const getData = () => {
  return async (dispatch: any, getState: any) => {
    try {
      let results = await axios.get(
        "https://us-central1-mebooks-plus.cloudfunctions.net/getPNMAnalytics"
      );
      dispatch({
        type: "UPDATE_ANALYTICS",
        payload: {
          analytics: results.data.analytics,
        },
      });
    } catch (err) {
      dispatch({
        type: "UPDATE_ANALYTICS",
        payload: {
          analytics: null,
        },
      });
    }
  };
};

export const getDataAttribute = async (collection: string, idList: [any]) => {
  try {
    let results = await axios.post(
      "https://us-central1-mebooks-plus.cloudfunctions.net/getPNMAnalyticsData",
      {
        collection: collection,
        id: idList,
      }
    );
    if (results) return results.data;
  } catch (err) {}
};
