import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authStateInterface } from "../store/reducers/authReducer";

enum deviceType {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
  NONE = "NONE",
}

interface componentState {
  deviceType: deviceType;
}

interface Props {
  location: any;
  authStore: authStateInterface;
}

class SecurityNavigator extends Component<Props> {
  state: componentState = {
    deviceType: deviceType.NONE,
  };

  componentDidMount() {
    this.handleSizeChange();
    window.addEventListener("resize", this.handleSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSizeChange);
  }

  handleSizeChange = () => {
    if (
      window.innerWidth <= 1000 &&
      this.state.deviceType === deviceType.DESKTOP
    ) {
      this.setState({
        deviceType: deviceType.MOBILE,
      });
    } else if (
      window.innerWidth > 1000 &&
      this.state.deviceType !== deviceType.DESKTOP
    ) {
      this.setState({
        deviceType: deviceType.DESKTOP,
      });
    }
  };

  render() {
    if (this.state.deviceType !== deviceType.NONE) {
      if (this.state.deviceType === deviceType.MOBILE)
        return <Redirect to="/mobile" />;
      else {
        if (
          this.props.authStore.userInfo &&
          (this.props.location.pathname === "/" ||
            this.props.location.pathname === "/mobile")
        )
          return <Redirect to="/dashboard" />;
        else if (!this.props.authStore.userInfo) return <Redirect to="/" />;
        else return null;
      }
    } else return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(SecurityNavigator);
