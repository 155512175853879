import { Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//Assets
import Avatar from "../assets/avatar.png";
//Action
import { signOut } from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { Colors } from "../styles/Colors";
import { RedButton, RowContainer, VerticalContainer } from "../styles/Layout";

const { Title, Link } = Typography;

interface Props {
  history: any;
  handleAuthModal: () => void;
  authStore: authStateInterface;
}

class AuthModal extends Component<Props> {
  handleInnerModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    return (
      <Overlay onClick={this.props.handleAuthModal}>
        <InnerModal onClick={this.handleInnerModal}>
          <VerticalContainer style={{ alignItems: "flex-start" }}>
            <RowContainer style={{ marginBottom: 20 }}>
              <img
                alt="Admin Avatar"
                src={Avatar}
                style={{ width: "auto", height: 50, marginRight: 20 }}
              />
              <VerticalContainer style={{ alignItems: "flex-start" }}>
                <Title level={5}>Admin</Title>
                <Title level={5} style={{ maxWidth: 250 }}>
                  {this.props.authStore.userInfo &&
                    this.props.authStore.userInfo.email}
                </Title>
              </VerticalContainer>
            </RowContainer>
            <CustomLastLoginTitle level={5}>
              {`Last Login: ${new Date().toDateString()}`}
            </CustomLastLoginTitle>
            <Link
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                this.props.history.push("/reset-password");
              }}
            >
              Change Password
            </Link>
          </VerticalContainer>
          <VerticalContainer style={{ marginTop: 25, alignItems: "flex-end" }}>
            <RedButton onClick={signOut}>Log Out </RedButton>
          </VerticalContainer>
        </InnerModal>
      </Overlay>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(AuthModal);

const Overlay = styled(VerticalContainer)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  margin: auto;
  z-index: 5;
`;

const InnerModal = styled.div`
  position: absolute;
  top: 50px;
  right: 2.5%;
  width: 380px;
  border-radius: 20px;
  background: white;
  padding: 35px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  z-index: 5;
`;

const CustomLastLoginTitle = styled(Title)`
  color: ${Colors.Grey} !important;
  margin-bottom: 5px !important;
`;
