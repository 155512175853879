import firebase from "../../utils/firebase";

//================================================================
//INFO : Auth Listener
let unsubscribeAuthListener: any = null;
export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    unsubscribeAuthListener = firebase
      .auth()
      .onAuthStateChanged(function (user) {
        if (user) {
          dispatch({
            type: "UPDATE_USER_INFO",
            payload: {
              userInfo: user,
            },
          });
        } else
          dispatch({
            type: "UPDATE_USER_INFO",
            payload: {
              userInfo: null,
            },
          });
        dispatch({ type: "UPDATE_USER_LOADING" });
      });
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
  };
};

//================================================================
//INFO : Auth Actions

//INFO : Action to sign in MeBooks Dashboard
export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password);
    return "";
  } catch (err) {
    return err.message;
  }
};

//INFO : Action to sign out MeBooks Dashboard
export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {}
};

//INFO : Action to sign out MeBooks Dashboard
export const resetPassword = async (email: string) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return "";
  } catch (err) {
    return err.message;
  }
};

export const changePassword = async (password: string) => {
  try {
    const user = firebase.auth().currentUser;
    if (user) {
      await user.updatePassword(password);
    }
    return "";
  } catch (err) {
    return err.message;
  }
};
